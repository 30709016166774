<template>
  <v-lazy
    :options="{
      threshold: .5
    }"
  >
    <v-container>
      <ManagePopupHeader />
      <ManagePopupContent ref="content" :pagination="pagination" @refresh="getPagination" />
      <v-sheet class="pa-3 d-flex">
        <v-row>
          <v-col cols="12" md="3">
            <v-dialog
              v-model="showRegister"
              width="600px"
              :fullscreen="mobile"
              :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
            >
              <template #activator="{ attrs, on }">
                <v-btn
                  class="mr-1 text-body-1"
                  large
                  v-bind="attrs"
                  v-on="on"
                >
                  추가
                </v-btn>
              </template>
              <ManagePopupRegister @close-register="closeRegister" />
            </v-dialog>
            <v-btn
              class="text-body-1"
              large
              @click="deletePopup"
            >
              삭제
            </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <Pagination
              ref="pagination"
              :pagination="pagination"
              :show-num="7"
              :base-url="'/manage/popup/'"
              :query="$route.query"
            />
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
  </v-lazy>
</template>

<script>
  import Pagination from '@/components/core/Pagination'
  import ManagePopupHeader from '@/views/manage/popup/Header'
  import ManagePopupContent from '@/views/manage/popup/Content'
  import ManagePopupRegister from '@/views/manage/popup/Register'
  export default {
    name: 'Popup',
    components: { ManagePopupRegister, ManagePopupContent, ManagePopupHeader, Pagination },
    data () {
      return {
        pagination: {
          list: [],
          pageNum: this.$route.params.pageNum,
        },
        showRegister: false,
      }
    },
    computed: {
      pageNum () {
        return this.$route.params.pageNum
      },
      mobile () {
        return this.$isMobile
      },
      selected () {
        return this.$refs.content.selected
      },
    },
    watch: {
      pageNum () {
        this.getPagination()
      },
    },
    beforeMount () {
      this.getPagination()
    },
    methods: {
      getPagination (payload) {
        const url = `/manage/popup/popups/${payload && payload.pageNum ? payload.pageNum : this.pageNum}`
        const data = {
          pageDataSize: this.pageDataSize,
        }
        this.$axios.get(url, {
          params: data,
        })
          .then(res => {
            if (res && res.data) {
              this.pagination = res.data
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      async deletePopup () {
        const url = '/manage/popup/delete'
        const data = {
          idxes: this.selected,
        }
        await this.$axios.delete(url, { data: data }).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            alert(res.data.message)
            this.getPagination()
          } else {
            alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
          }
        }).catch(e => {
          alert('오류가 발생하였습니다.')
        })
      },
      closeRegister () {
        this.showRegister = false
        this.getPagination()
      },
    },
  }
</script>

<style scoped>

</style>
