<template>
  <v-row>
    <v-col cols="12">
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th>
                <v-checkbox v-model="checkAll" />
              </th>
              <th />
              <th
                v-for="(col, i) in cols"
                :id="'header_' + i"
                :key="i"
                class="text-subtitle-1 text-center"
              >
                {{ col }}
              </th>
            </tr>
          </thead>
          <tbody v-if="!pagination || pagination.list.length === 0">
            <tr>
              <td :colspan="cols.length + 2">
                <p class="text-body-1 my-1 text-center">
                  등록된 팝업이 존재하지 않습니다.
                </p>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="item in pagination.list" :key="item.idx">
              <td class="text-center">
                <v-checkbox v-model="selected" :value="item.idx" />
              </td>
              <td>
                <v-dialog
                  v-model="item.showEdit"
                  persistent
                  width="600px"
                  :fullscreen="mobile"
                  :transition="mobile ? 'dialog-bottom-transition' : 'scale-transition'"
                >
                  <template #activator="{ attrs, on }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-square-edit-outline
                    </v-icon>
                  </template>
                  <ManagePopupEdit :popup="item" @close-dialog="closeDialog" />
                </v-dialog>
              </td>
              <td class="text-center">
                <span class="text-truncate">
                  {{ item.title }}
                </span>
              </td>
              <td class="text-center">
                <span class="text-truncate">
                  {{ item.positionX }}px
                </span>
              </td>
              <td class="text-center">
                <span class="text-truncate">
                  {{ item.positionY }}px
                </span>
              </td>
              <td class="text-center">
                <span class="text-truncate">
                  {{ item.width }}px
                </span>
              </td>
              <td class="text-center">
                <span class="text-truncate">
                  {{ item.height }}px
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
  import ManagePopupEdit from '@/views/manage/popup/Edit'
  export default {
    name: 'ManagePopupContent',
    components: { ManagePopupEdit },
    props: {
      pagination: {},
    },
    data () {
      return {
        cols: ['Title', 'X', 'Y', 'Width', 'Height'],
        selected: [],
      }
    },
    computed: {
      mobile () {
        return this.$isMobile
      },
      checkAll: {
        get () {
          return this.pagination ? (this.selected ? (this.selected.length === this.pagination.list.length) : false) : false
        },
        set (value) {
          const selected = []
          if (value) {
            this.pagination.list.forEach(user => {
              selected.push(user.idx)
            })
            this.selected = selected
          } else {
            this.selected = []
          }
        },
      },
    },
    methods: {
      closeDialog (popup) {
        popup.showEdit = false
        this.$emit('refresh')
      },
    },
  }
</script>

<style scoped>

</style>
