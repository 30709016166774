<template>
  <v-card v-if="popup">
    <v-card-title class="pt-10 pt-md-3">
      <v-icon class="mr-1">
        mdi-square-edit-outline
      </v-icon>
      팝업 수정
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="thisPopup.title"
            label="제목"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="thisPopup.width"
            label="가로"
            type="number"
            hide-spin-buttons
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="thisPopup.height"
            label="세로"
            type="number"
            hide-spin-buttons
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="thisPopup.positionX"
            label="X 위치"
            type="number"
            hide-spin-buttons
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="thisPopup.positionY"
            label="Y 위치"
            type="number"
            hide-spin-buttons
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <Editor ref="editor" :content="thisPopup.content" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="flex-wrap pa-5 pt-15 pt-md-5">
      <v-btn
        class="mt-5"
        large
        width="100%"
        @click="savePopup"
      >
        저장
      </v-btn>
      <v-btn
        class="mx-0 mt-2"
        large
        width="100%"
        @click="close"
      >
        닫기
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import Editor from '@/components/core/Editor'
  export default {
    name: 'ManagePopupEdit',
    components: { Editor },
    props: {
      popup: {},
    },
    computed: {
      thisPopup () {
        return this.popup
      },
      content () {
        return this.$refs.editor.getContent()
      },
    },
    methods: {
      async savePopup () {
        const url = '/manage/popup'
        this.thisPopup.content = this.$refs.editor.getContent()
        await this.$axios.patch(url, this.thisPopup).then(res => {
          if (res && res.data && res.data.status === 'SUCCESS') {
            alert('처리를 완료하였습니다.')
            this.close()
          } else {
            alert(res.data.message ? res.data.message : '오류가 발생하였습니다.')
          }
        }).catch(e => {
          alert('오류가 발생하였습니다.')
        })
      },
      close () {
        this.$emit('close-dialog', this.popup)
      },
    },
  }
</script>

<style scoped>

</style>
